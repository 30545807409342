import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql";
import FooterNavGroup from "./FooterNavGroup";
import tw from "twin.macro";
import Link from "./BaseLink";

const Nav = tw.nav`
  flex flex-col md:flex-row md:items-start md:justify-between md:px-12 md:h-auto z-30 md:py-8 text-gray-700 text-center max-w-7xl md:mx-auto
`;

const NavLink = tw(Link)`
  block px-3 py-3 md:px-2 md:py-1 rounded-md text-base font-medium focus:outline-none focus:text-gray-900 focus:bg-white transition duration-150 uppercase hover:text-fire ease-in-out
`;

const NavGroup = tw.ul`
  flex flex-col p-4 items-start
`;

const Navigation = () => (
  <Nav>
    <NavGroup>
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li>
        <NavLink to="/drivers">Become a Driver</NavLink>
      </li>
      <li>
        <NavLink to="/faq">FAQ</NavLink>
      </li>
      <li>
        <NavLink to="/about">About</NavLink>
      </li>
    </NavGroup>
    <StaticQuery
      query={`${navQuery}`}
      render={withPreview((data) => {
        const useCases = data.prismic.allUse_cases.edges;
        const cities = data.prismic.allCitys.edges;
        const staticPages = data.prismic.allStatic_pages.edges;
        return (
          <>
            {useCases.length > 0 && (
              <FooterNavGroup label="Use Jeevz For:" nodes={useCases} />
            )}
            {cities.length > 0 && (
              <FooterNavGroup label="Cities" nodes={cities} />
            )}
            {staticPages.length > 0 && <FooterNavGroup nodes={staticPages} />}
          </>
        );
      }, navQuery)}
    />
  </Nav>
);

export default Navigation;

const navQuery = graphql`
  query {
    prismic {
      allUse_cases(where: { live: true }) {
        edges {
          node {
            page_title
            _meta {
              uid
              type
            }
          }
        }
      }
      allLanding_pages(where: { live: true }) {
        edges {
          node {
            page_title
            _meta {
              uid
              type
            }
          }
        }
      }
      allCitys(where: { live: true }) {
        edges {
          node {
            page_title
            _meta {
              uid
              type
            }
          }
        }
      }
      allStatic_pages {
        edges {
          node {
            page_title
            _meta {
              uid
              type
            }
          }
        }
      }
    }
  }
`;
