export const toPath = (type, uid) => {
  switch (type) {
    case "use_case":
      return casePath(uid);
    case "landing_page":
      return landingPagePath(uid);
    case "profession":
      return professionPath(uid);
    case "city":
      return cityPath(uid);
    default:
      return `/${uid}`;
  }
};

export const casePath = (uid) => `/for/${uid}`;

export const professionPath = (uid) => `/for/${uid}`;

export const cityPath = (uid) => `/cities/${uid}`;

export const landingPagePath = (uid) => `/for/${uid}`;
