import React from "react";
import tw from "twin.macro";
import FooterNav from "./FooterNav";
import SocialLinks from "./SocialLinks";
import { Helmet } from "react-helmet";

const FooterWrapper = tw.div`
  relative w-full mx-0 py-12 px-4 overflow-hidden sm:px-6 lg:px-8 flex-none bg-gray-100
`;

const Copyright = tw.p`
  mt-8 text-center text-base leading-6 text-gray-400
`;

const Footer = (props) => {
  return <FooterWrapper>
    <FooterNav />
    <SocialLinks />
    <Copyright>&copy; 2023 Jeevz, Inc. All rights reserved.</Copyright>

    <Helmet>
      <script type='text/javascript'>
        {`
          if (window.location.search) {
            const params = window.location.search.replace('?', '').split("&");
            const accountParam = params.find(el => el.indexOf("profitwellAccount=") !== -1);

            if (accountParam && accountParam.split('=')[1] === "redcap") {
              window.dataProfitwellAuth = 'bdf11b084f116a140ee5b0929a639bb0';
            }
          }

          window.dataProfitwellAuth ||= '9ac8f903ac20a99d43092adc6746b612';

          let script = document.createElement('script')
          script.setAttribute('id', 'profitwell-js')
          script.setAttribute('data-pw-auth', window.dataProfitwellAuth)
          script.innerHTML = "(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+window.dataProfitwellAuth;m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');profitwell('start', {});"
          document.head.appendChild(script);
        `}
      </script>
    </Helmet>

    <Helmet>
      <script type="text/javascript">
        {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "928fdbda-77d5-4ee4-a650-3c750feca995"}})
        AF('banners', 'showBanner')`}
      </script>
    </Helmet>

    <Helmet>
      <script type="text/javascript">
        {`!function(e,t,a,i,d,s,n,r){e._dixa=e._dixa||function(){(e._dixa.q=e._dixa.q||[]).push(arguments),e._dixa.handler&&e._dixa.handler(arguments)},e._dixa.l=1*new Date,e._dixa.wid="7b7cc658-9949-4486-8ca5-dd8e26210b79",n=t.createElement(a),r=t.getElementsByTagName(a)[0],n.async=1,n.setAttribute("charset","utf-8"),n.src="https://widget.dixa.io/assets/scripts/javascript/loader.js",r.parentNode.insertBefore(n,r)}(window,document,"script")`}
      </script>
    </Helmet>

    <Helmet>
        <script type="text/javascript">
          {`!function(e,l,v,i,o,n){e[i]||(e[i]={}),e[i].account_id=n;var g,h;g=l.createElement(v),g.type="text/javascript",g.async=1,g.src=o+n,h=l.getElementsByTagName(v)[0],h.parentNode.insertBefore(g,h);e[i].q=[];e[i].on=function(z,y){e[i].q.push([z,y])}}(window,document,"script","_elev","https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=","63403fbef008b");`}
        </script>
      </Helmet>
  </FooterWrapper>
};

export default Footer;
