import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql";
import tw from "twin.macro";
import Link from "./BaseLink";
import { SR } from "../utils/helpers";

const Links = tw.ul`
  flex flex-row mt-8 justify-center
`;

const Icon = tw(Link)`
text-gray-400 hover:text-gray-500 h-6 w-6 m-1
`;

const SocialLinks = () => (
  <>
    <StaticQuery
      query={`${socialQuery}`}
      render={withPreview((data) => {
        const links = data.prismic.allFooters.edges[0].node.social_links;
        return (
          <Links>
            {links.map(({ link, social_icon, social_name }, idx) => (
              <Icon to={link.url} key={`${social_name}-link`}>
                <SR>{social_name}</SR>
                <img src={social_icon.url} alt={social_name} />
              </Icon>
            ))}
          </Links>
        );
      }, socialQuery)}
    />
  </>
);

export default SocialLinks;

const socialQuery = graphql`
  query {
    prismic {
      allFooters {
        edges {
          node {
            social_links {
              link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  target
                  url
                }
              }
              social_icon
              social_name
            }
          }
        }
      }
    }
  }
`;
