import React from "react";
import tw from "twin.macro";
import Footer from "../components/Footer";
import "../utils/globals.css";

const PageContainer = tw.div`
    w-full font-sans mx-auto flex flex-col items-stretch min-h-screen text-ink
`;

const Main = tw.div`
    flex-grow
`;

const Layout = ({ children, hideFooter }) => (
  <PageContainer>
    <Main>{children}</Main>
    { !hideFooter && <Footer /> }
  </PageContainer>
);

export default Layout;
