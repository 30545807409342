import React from "react";
import { RichText } from "prismic-reactjs";
import tw from "twin.macro";
import Link from "./BaseLink";
import { toPath } from "../utils/paths";

const NavLink = tw(Link)`
  block px-3 py-3 md:px-2 md:py-1 rounded-md text-base font-medium focus:outline-none focus:text-gray-900 focus:bg-white transition duration-150 uppercase hover:text-fire ease-in-out
`;

const GroupLabel = tw.li`
  block px-3 py-3 md:px-2 md:py-1 font-light uppercase tracking-wider
`;

const NavGroup = tw.ul`
  flex flex-col p-4 items-start
`;

const FooterNavGroup = ({ label, nodes }) => {
  // console.log("NODES ", nodes);
  const data = Object.values(nodes);
  // console.log("DATA ", data);
  if (!data || data.length < 1) return null;
  return (
    <NavGroup>
      {label && <GroupLabel>{label}</GroupLabel>}
      {data.map(({ node }, idx) => (
        <li key={`${node._meta.type}-${idx}`}>
          <NavLink to={toPath(node._meta.type, node._meta.uid)}>
            {RichText.asText(node.page_title)}
          </NavLink>
        </li>
      ))}
    </NavGroup>
  );
};

export default FooterNavGroup;
